@font-face {
  font-family: 'Sagnika Regular';
  src: url('fonts/Signika-Regular.ttf');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Sagnika Bold';
  src: url('fonts/Signika-Bold.ttf');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: 'Sagnika Regular', 'Sagnika Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

